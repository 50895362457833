<template>
  <div class="list-table">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-subheader>คณะศึกษาดูงาน</v-subheader>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar flat color="white">
              <v-btn color="primary" @click="handleAddItem">เพิ่ม</v-btn>
              <v-btn
                color="success"
                :loading="loading"
                :disabled="loading"
                class="ml-1"
                @click="handleSync"
                >ซิงค์ข้อมูล LU</v-btn
              >
              <v-text-field
                id
                name
                flat
                prepend-icon="search"
                placeholder="รหัสคณะ/ชื่อคณะ"
                v-model="search"
                hide-details
                class="hidden-sm-and-down"
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <!-- Data table -->
              <v-data-table
                :page="page"
                :pageCount="totalPage"
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :options.sync="options"
                :loading="loading"
                :server-items-length="total"
                class="elevation-1"
                item-key="code"
                v-model="complex.selected"
              >
                <template v-slot:item.avatar="{ item }">
                  <v-avatar>
                    <img :src="item.avatar" alt="avatar" size="16" />
                  </v-avatar>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-menu>
                    <template v-slot:activator="{ on: menu }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn icon v-on="{ ...menu }">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <span>Action</span>
                      </v-tooltip>
                    </template>
                    <v-list class="pa-0" dense>
                      <v-list-item
                        v-for="action in actions"
                        :key="action.text"
                        @click="action.click(item)"
                      >
                        <v-list-item-icon class="mr-2">
                          <v-icon small>{{ action.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ action.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Dialog edit -->
    <v-dialog v-model="dialogFormGroup" max-width="150vh">
      <v-card>
        <v-toolbar card>เพิ่ม/แก้ไข คณะศึกษาดูงาน</v-toolbar>
        <v-card-text>
          <form>
            <input v-if="editMode" type="hidden" v-model="formModel.id" />
            <v-text-field
              v-model="formModel.code"
              :counter="50"
              label="รหัสคณะ"
              required
            ></v-text-field>
            <v-text-field
              v-model="formModel.name"
              :counter="100"
              label="ชื่อคณะ"
              required
            ></v-text-field>
            <v-divider class="mt-3 mb-3"></v-divider>
            <v-btn @click="handleSubmit">บันทึก</v-btn>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Util from '@/util'
import { getGroup, updateGroup, newGroup, syncData } from '@/api/group'
// import { lecturer as Lecturer } from '@/api/lecturer'
export default {
  name: 'GroupList',
  data() {
    return {
      loginUser: null,
      loading: false,
      page: 1,
      totalPage: 0,
      total: 0,
      options: {},
      editMode: false,
      formModel: {
        id: '',
        name: ''
      },
      dialogFormGroup: false,
      search: '',
      complex: {
        selected: [],
        headers: [
          {
            text: '#',
            value: 'id'
          },
          {
            text: 'รหัสคณะ',
            value: 'code'
          },
          {
            text: 'ชื่อคณะ',
            value: 'name'
          },
          {
            text: 'จัดการ',
            value: 'action'
          }
        ],
        items: []
      },
      actions: [
        // {
        //   text: 'รายละเอียด',
        //   icon: 'mdi-eye',
        //   click: this.handleViewItem
        // },
        {
          text: 'แก้ไข',
          icon: 'mdi-pencil',
          click: this.handleEditItem
        },
        {
          text: 'ลบ',
          icon: 'mdi-close',
          click: this.handleDeleteItem
        }
      ]
    }
  },
  created() {
    // this.fetchData()
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'getLoginUser'])
  },
  mounted() {
    this.loginUser = this.getLoginUser
    this.fetchData()
  },
  //this one will populate new data set when user changes current page.
  watch: {
    options: {
      handler() {
        this.fetchData()
      }
    },
    deep: true
  },
  methods: {
    fetchData() {
      this.loading = true
      const { page, itemsPerPage } = this.options
      let offset = (page - 1) * itemsPerPage
      getGroup('', itemsPerPage, offset)
        .then(({ groups, options }) => {
          this.complex.items = groups
          this.totalPage = options.totalPage
          this.total = options.total
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSubmit() {
      this.loading = true
      if (this.editMode) {
        updateGroup(this.formModel.id, this.formModel)
          .then(() => {
            this.$notify({
              type: 'info',
              group: 'notify',
              text: 'แก้ไขข้อมูลเรียบร้อยแล้ว'
            })
            this.dialogFormGroup = false
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        newGroup(this.formModel)
          .then((group) => {
            this.complex.items.push(group)
            this.$notify({
              type: 'info',
              group: 'notify',
              text: 'เพิ่มข้อมูลเรียบร้อยแล้ว'
            })
            this.dialogFormGroup = false
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleSync() {
      this.loading = true
      syncData(this.loginUser.lu_token)
        .then((synced) => {
          Util.syncedDialog(this.$alert, synced)
          this.fetchData()
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleAddItem() {
      this.editMode = false
      this.formModel = {}
      this.dialogFormGroup = true
    },
    handleClick(row) {
      this.formModel = Object.assign(this.formModel, row)
      this.dialogFormGroup = true
    },
    // handleViewItem(row) {
    //   console.log(row)
    // },
    handleEditItem(row) {
      Util.goToLuSystem(this.loginUser.lu_token, `/search?q=${row.code}`)
      // this.formModel = row
      // this.editMode = true
      // this.dialogFormGroup = true
    },
    handleDeleteItem() {}
  }
}
</script>
